.stellco {
  &_food {
    background: var(--stell-white) url('../../images/stell-about-3-food-full-width.png') -500px 0 no-repeat;
    background-size: cover;
    height: 100vh;
    position: relative;
    width: 100vw;
    z-index: 1;

    @media (min-width: 375px) 
    and (min-height: 812px) {
      background: var(--stell-white) url('../../images/stell-about-3-food-full-width.png') -1400px 0 no-repeat;
      height: 100vh;
    }
    
    @media (min-width: 720px) {
      background: var(--stell-white) url('../../images/stell-about-3-food-full-width.png') -1225px 0 no-repeat;
      height: 100vh;
    }

    @media (min-width: 768px) {
      background: var(--stell-white) url('../../images/stell-about-3-food-full-width.png') -1400px 0 no-repeat;
      height: 100vh;
    }

    @media (min-width: 1024px) {
      background: var(--stell-white) url('../../images/stell-about-3-food-full-width.png') -1000px 0 no-repeat;
      transition: all .25s ease-out;
      z-index: 99;
    }
    
    @media (min-width: 1440px) {
      background: var(--stell-white) url('../../images/stell-about-3-food-full-width.png') top center no-repeat;
      transition: all .25s ease-out;
      z-index: 99;
    }

    &__content {
      // border: thin solid red;
      left: 50%;
      position: absolute;
      top: 45%;
      transform: translate(-50%, -50%);
      width: 90%;

      @media (min-width: 320px) {
        width: 80%;
      }
      
      @media (min-width: 768px) {
        // border: thin solid blue;
        width: 65%;
      }
      
      @media (min-width: 768px)
      and (min-height: 1024px) {
        // border: thin solid white;
        width: 80%;
      }

      @media (min-width: 1024px) {
        left: auto;
        right: 0;
        position: absolute;
        top: 45%;
        transform: translateY(-50%);
        width: 60%;
      }
      
      @media (min-width: 1024px)
      and (min-height: 1366px) {
        left: 50%;
        right: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 75%;
      }
      
      @media (min-width: 1280px) {
        width: 55%;
      }
      
      @media (min-width: 1440px) {
        left: auto;
        right: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 60%;
      }
      
      @media (min-width: 1600px) {
        left: auto;
        right: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 50%;
      }
      
      @media (min-width: 1920px) {
        left: auto;
        right: 50px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 35%;
      }
    }

    &__header {
      @include JanaThorkProBold;
      color: var(--stell-white);
      font-size: 60px;
      line-height: .8;
      padding-bottom: 15px;
      text-transform: uppercase;

      @media (min-width: 375px) {
        font-size: 70px;
      }
      
      @media (min-width: 400px) {
        font-size: 80px;
      }

      @media (min-width: 768px) {
        font-size: 105px;
      }

      @media (min-width: 768px)
      and (min-height: 1024px) {
        font-size: 145px;
      }

      @media (min-width: 768px)
      and (min-height: 812px) {
        font-size: 125px;
      }
      
      @media (min-width: 812px)
      and (min-height: 768px) {
        font-size: 125px;
      }
      
      @media (min-width: 1024px) {
        font-size: 120px;
      }
      
      @media (min-width: 1024px)
      and (min-height: 1366px) {
        font-size: 180px;
      }
      
      @media (min-width: 1280px) {
        font-size: 150px;
      }
      
      @media (min-width: 1900px) {
        font-size: 200px;
      }
    }
    
    &__description {
      // border: thin solid white;
      color: var(--stell-white);
      font-size: 14px;
      line-height: 1.5;

      @media (min-width: 375px) {
        font-size: 18px;
      }

      @media (min-width: 768px) {
        font-size: 18px;
      }

      @media (min-width: 768px)
      and (min-height: 1024px) {
        font-size: 24px;
      }

      @media (min-width: 1024px) {
        font-size: 18px;
        padding: 20px 0 0 10px;
        width: 80%;
      }
      
      @media (min-width: 1024px)
      and (min-height: 1366px) {
        font-size: 24px;
        line-height: 1.5;
        padding: 0;
        width: 100%;
      }
      
      @media (min-width: 1440px) {
        font-size: 18px;
        padding: 20px 0 0 20px;
        width: 60%;
      }
      
      @media (min-width: 1900px) {
        font-size: 21px;
        padding: 20px 0 0 20px;
        width: 90%;
      }
    }
  }
}