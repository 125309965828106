.stellco {
  &_roasted {
    height: 300vh;
    position: relative;
    width: 100vw;

    &__bean {
      height: 50px;
      width: 50px;

      @media (min-width: 480px) {
        height: 150px;
        width: 150px;
      }
      
      @media (min-width: 540px) {
        height: 100px;
        width: 100px;
      }
      
      @media (min-width: 720px) {
        height: 60px;
        width: 60px;
      }

      @media (min-width: 1024px) {
        height: 90px;
        width: 90px;
      }

      @media (min-width: 1440px) {
        height: 150px;
        width: 150px;
      }

      &[data-depth='1'] {
        left: 62%;
        position: absolute;
        top: -3%;
        z-index: 9;
      }

      &[data-depth='2'] {
        left: 28%;
        position: absolute;
        top: 10%;
        z-index: 10;
      }
      
      &[data-depth='3'] {
        left: 58%;
        position: absolute;
        top: 25%;
        z-index: 5;
      }
      
      &[data-depth='4'] {
        left: 30%;
        position: absolute;
        top: 40%;
        z-index: 3;
      }
      
      &[data-depth='5'] {
        left: 60%;
        position: absolute;
        top: 65%;
        z-index: 6;
      }
      
      &[data-depth='6'] {
        // border: thin solid white;
        left: 70%;
        position: absolute;
        top: 85%;
        z-index: 6;
      }
      
      &[data-depth='7'] {
        left: 20%;
        position: absolute;
        top: 115%;
        z-index: 6;
      }
      
      &[data-depth='8'] { //leaf
        height: 100px;
        left: 20%;
        position: absolute;
        top: 95%;
        width: 100px;
        z-index: 6;

        @media (min-width: 768px) {
          height: 200px;
          width: 200px;
        }
        
        @media (min-width: 1440px) {
          height: 250px;
          width: 250px;
        }
      }
      
      &[data-depth='9'] { //leaf
        height: 100px;
        left: 60%;
        position: absolute;
        top: 175%;
        width: 100px;
        z-index: 6;

        @media (min-width: 768px) {
          height: 200px;
          width: 200px;
        }
        
        @media (min-width: 1024px) {
          left: 25%;
          height: 250px;
          width: 250px;
        }
      }

      &_img {
        height: auto;
        width: 100%;
      }
    }
  }
  
  &_slogan {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100vh;
    width: 100%;
    z-index: 21;

    @keyframes fadeInEffect {
      0% {
        top: -50px;
      };
      100% {
        top: 0;
      };
    }

    .beans {
      animation: fadeInEffect 2s 1;
      height: 200vh;
      position: absolute;
      top: 0;
      width: 100vw;
    }
    
    &__wrapper {
      height: 145px;
      left: 50%;
      position: absolute;
      top: 45%;
      transform: translate(-50%, -50%);
      width: 100%;

      @media (min-width: 320px) {
        height: 160px;
      }

      @media (min-width: 360px) {
        height: 195px;
        width: 90%;
      }
      
      @media (min-width: 375px) {
        height: 195px;
        width: 90%;
      }
      
      @media (min-width: 400px) {
        height: 200px;
      }
      
      @media (min-width: 480px) {
        height: 270px;
        top: 48%;
      }
      
      @media (min-width: 700px) {
        height: 360px;
        top: 52%;
      }
      
      @media (min-width: 768px) {
        top: 50%;
      }

      @media (min-width: 768px) 
      and (min-height: 1024px) {
        top: 40%;
      }
      
      @media (min-width: 1024px) {
        height: 425px;
        top: 50%;
      }

      @media (min-width: 1024px)
      and (min-height: 1366px) {
        height: 525px;
      }
      
      @media (min-width: 1280px) {
        height: 625px;
      }
      
      @media (min-width: 1440px) {
        height: 550px;
        top: 55%;
      }
      
      @media (min-width: 1600px) {
        height: 750px;
        top: 55%;
      }
      
      @media (min-width: 1900px) {
        height: 880px;
        top: 50%;
      }
    }
    
    &__header {
      line-height: .85;
      z-index: 7;
    }

    .nautical {
      @include JanaThorkProBold;
      color: var(--stell-cyan);
      left: 50%;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
    }
    
    @keyframes blurEffect {
      0% {
        filter: blur(1.5rem);
      };
      100% {
        filter: blur(0);
      };
    }

    &__redlands {
      @extend .nautical;
      animation: ease-out blurEffect 2s;
      animation-iteration-count: 1;
      font-size: 2.7rem;
      top: 0;
      transform: translateX(-50%);
      z-index: 7;

      @media (min-width: 320px) {
        font-size: 3rem;
      }

      @media (min-width: 360px) {
        font-size: 3.5rem;
      }

      @media (min-width: 400px) {
        font-size: 3.8rem;
      }
      
      @media (min-width: 480px) {
        font-size: 12.55rem;
      }
      
      @media (min-width: 540px) {
        font-size: 5.30rem;
      }
      
      @media (min-width: 700px) {
        font-size: 7rem;
      }
      
      @media (min-width: 1024px) {
        font-size: 8.5rem;
      }
      
      @media (min-width: 1024px)
      and (min-height: 1366px) {
        font-size: 10rem;
      }
      
      @media (min-width: 1280px) {
        font-size: 12.5rem;
      }
      
      @media (min-width: 1440px) {
        font-size: 10.55rem;
      }
      
      @media (min-width: 1600px) {
        font-size: 15rem;
      }
      
      @media (min-width: 1920px) {
        font-size: 17.5rem;
      }
    }
    
    &__coffee {
      @extend .nautical;
      animation: ease-out blurEffect 2s;
      animation-iteration-count: 1;
      font-size: 3.75rem;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;

      @media (min-width: 320px) {
        font-size: 4.25rem;
      }

      @media (min-width: 360px) {
        font-size: 4.9rem;
      }

      @media (min-width: 375px) {
        font-size: 5rem;
      }

      @media (min-width: 400px) {
        font-size: 5.5rem;
      }

      @media (min-width: 480px) {
        font-size: 17.95rem;
      }
      
      @media (min-width: 540px) {
        font-size: 7.5rem;
      }

      // @media (min-width: 720px) {
      //   font-size: 7.1rem;
      //   top: 50%;
      // }

      @media (min-width: 700px) {
        font-size: 9.9rem;
        top: 50%;
      }
      
      @media (min-width: 1024px) {
        font-size: 12rem;
      }
      
      @media (min-width: 1024px)
      and (min-height: 1366px) {
        font-size: 13.95rem;
      }

      @media (min-width: 1280px) {
        font-size: 17.5rem;
      }

      @media (min-width: 1440px) {
        font-size: 15rem;
        top: 50%;
      }

      @media (min-width: 1600px) {
        font-size: 21rem;
      }
      
      @media (min-width: 1920px) {
        font-size: 25rem;
        top: 50%;
      }
    }
    
    &__roasters {
      @extend .nautical;
      animation: ease-out blurEffect 2s;
      animation-iteration-count: 1;
      font-size: 2.75rem;
      bottom: 0;
      transform: translateX(-50%);
      z-index: 5;

      @media (min-width: 320px) {
        font-size: 3.1rem;
      }

      @media (min-width: 360px) {
        font-size: 3.6rem;
        // bottom: 36%;
      }

      @media (min-width: 400px) {
        font-size: 4rem;
      }
      
      @media (min-width: 540px) {
        font-size: 5.4rem;
        // bottom: 30%;
      }
      
      @media (min-width: 700px) {
        font-size: 7.2rem;
      }

      @media (min-width: 768px)
      and (min-height: 1024px) {
        font-size: 7.2rem;
      }
      
      @media (min-width: 1024px) {
        font-size: 8.7rem;
      }

      @media (min-width: 1024px)
      and (min-height: 1366px) {
        font-size: 10.25rem;
      }

      @media (min-width: 1280px) {
        font-size: 13rem;
      }

      @media (min-width: 1440px) {
        font-size: 11rem;
      }
      
      @media (min-width: 1600px) {
        font-size: 15rem;
      }

      @media (min-width: 1920px) {
        font-size: 18rem;
      }
    }
  }

  .stellco_order_text {
    position: absolute;
    bottom: 185px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    text-align: center;
    width: 60%;

    @media (min-width: 344px) {
      bottom: 250px;
      font-size: 24px;
    }

    @media (min-width: 360px) {
      bottom: 210px;
      font-size: 24px;
    }

    @media (min-width: 390px) {
      bottom: 245px;
      font-size: 28px;
    }

    @media (min-width: 400px) {
      bottom: 270px;
    }
    
    @media (min-width: 540px) {
      bottom: 190px;
      font-size: 20px;
    }

    @media (min-width: 768px) {
      bottom: 300px;
      font-size: 32px;
      width: 100%;
    }
    
    @media (min-width: 820px) {
      bottom: 400px;
      font-size: 36px;
      width: 100%;
    }
    
    @media (min-width: 1024px) {
      bottom: 160px;
      font-size: 24px;
      width: 100%;
    }
    
    @media (min-width: 1024px) and (min-height: 1366px) {
      bottom: 380px;
      font-size: 36px;
      width: 100%;
    }

    @media (min-width: 1180px) {
      bottom: 185px;
      font-size: 24px;
      width: 100%;
    }
  
    @media (min-width: 1366px) {
      bottom: 220px;
      font-size: 21px;
      width: 100%;
    }
    
    @media (min-width: 1900px) {
      bottom: 220px;
      font-size: 28px;
      width: 100%;
    }
  }

  .stellco_order_text_pickup {
    color: var(--stell-black);
    font-weight: 400;
  }
  
  .stellco_order_text_destination {
    color: var(--stell-orange);
    font-weight: 600;
  }
}