html, body {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.stellco {
  height: 100vh;
  position: relative;
  width: 100vw;
}

.skip-to-main-content-link {
  background: var(--stell-orange);
  border-radius: 0;
  border-radius: 25px;
  color: var(--stell-white);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 15px 20px;
  text-transform: uppercase;
  text-decoration: none;
  transition: all .25s ease-out;
  left: 30px; 
  position: absolute; 
  top: 25px;
  opacity: 0;

  &:focus {
    opacity: 1;
  }
}