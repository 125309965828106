@mixin JanaThorkPro {
  font-family: jana-thork, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin JanaThorkProBold {
  font-family: jana-thork, sans-serif;
  font-weight: 700;
  font-style: normal;
}