.stellco {
  &_services {
    background: var(--stell-white) url('../../images/stell-about-5-service.png') 80% 0 no-repeat;
    background-size: cover;
    height: 100vh;
    position: relative;
    width: 100vw;

    @media (min-width: 768px) {
      background: var(--stell-white) url('../../images/stell-about-5-service.png') -1150px 0 no-repeat;
      height: 100vh;
    }

    @media (min-width: 1024px) {
      background: var(--stell-white) url('../../images/stell-about-5-service.png') -600px 0 no-repeat;
    }
    
    @media (min-width: 1920px) {
      background: var(--stell-white) url('../../images/stell-about-5-service.png') 0 0 no-repeat;
    }

    &__content {
      // border: thin solid white;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      z-index: 22;
      
      @media (min-width: 320px) {
        width: 80%;
      }
      
      @media (min-width: 400px) {
        left: 50%;
      }
      
      @media (min-width: 768px) {
        top: 50%;
        width: 65%;
      }
      
      @media (min-width: 768px)
      and (min-height: 1024px) {
        top: 40%;
        width: 80%;
      }

      @media (min-width: 1024px) {
        left: 40%;
        position: absolute;
        top: 45%;
        transform: translateY(-50%);
        width: 60%;
      }
      
      @media (min-width: 1024px)
      and (min-height: 1366px) {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 75%;
      }
      
      @media (min-width: 1280px) {
        left: auto;
        right: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 55%;
      }
      
      @media (min-width: 1440px) {
        left: auto;
        right: 12%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 45%;
      }
      
      @media (min-width: 1600px) {
        left: auto;
        right: 0%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 50%;
      }
      
      @media (min-width: 1900px) {
        left: auto;
        right: 100px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
      }
    }

    &__header {
      // border: thin solid white;
      @include JanaThorkProBold;
      color: var(--stell-white);
      font-size: 60px;
      line-height: .9;
      padding-bottom: 15px;
      text-transform: uppercase;
      // width: 80%;

      @media (min-width: 320px) {
        font-size: 64px;
      }

      @media (min-width: 375px) {
        font-size: 70px;
      }
      
      @media (min-width: 400px) {
        font-size: 80px;
      }

      @media (min-width: 768px) {
        font-size: 105px;
      }

      @media (min-width: 768px)
      and (min-height: 1024px) {
        font-size: 140px;
      }

      @media (min-width: 768px)
      and (min-height: 812px) {
        font-size: 125px;
      }
      
      @media (min-width: 812px)
      and (min-height: 768px) {
        font-size: 125px;
      }

      @media (min-width: 1024px) {
        font-size: 120px;
      }
      
      @media (min-width: 1024px)
      and (min-height: 1366px) {
        font-size: 180px;
      }

      @media (min-width: 1280px) {
        font-size: 150px;
      }
      
      @media (min-width: 1440px) {
        font-size: 150px;
      }
      
      @media (min-width: 1900px) {
        font-size: 200px;
      }
    }
    
    &__description {
      // border: thin solid white;
      bottom: 52%;
      color: var(--stell-white);
      font-size: 14px;
      line-height: 1.5;
      // width: 80%;

      @media (min-width: 375px) {
        font-size: 18px;
      }

      @media (min-width: 768px) {
        font-size: 14px;
        font-size: 18px;
      }

      @media (min-width: 768px)
      and (min-height: 1024px) {
        font-size: 18px;
      }
      
      @media (min-width: 1024px) {
        font-size: 18px;
        padding: 20px 0 0 10px;
        margin-top: 0;
        width: 80%;
      }
      
      @media (min-width: 1024px)
      and (min-height: 1366px) {
        font-size: 24px;
        width: 100%;
      }
      
      @media (min-width: 1280px) {
        font-size: 18px;
        padding: 20px 0 0 20px;
        margin-top: 0;
        width: 80%;
      }
      
      @media (min-width: 1900px) {
        font-size: 21px;
        padding: 20px 0 0 20px;
        margin-top: 0;
        width: 70%;
      }
    }
  }
}