.stellco {
  &_logo {
    left: 50%;
    position: absolute;
    top: 25px;
    transform: translateX(-50%);
    z-index: 150;

    &__img {
      background: url('../../images/stell-logo-large-0-orange.png') top center no-repeat;
      background-size: contain;
      width: 150px;
      height: 150px;

      &[data-img='true'] {
        background: url('../../images/stell-logo-large-0-white.png') top center no-repeat;
        background-size: contain;
        width: 150px;
        height: 150px;
      }
    }
  }
}