.stellco {
  &_mask {
    border-radius: 100%;
    height: 200px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 200px;
    z-index: 3;

    @media (min-width: 375px) 
    and (min-height: 812px) {
      height: 250px;
      width: 250px;
    }
    
    @media (min-width: 400px) {
      height: 250px;
      width: 250px;
    }

    @media (min-width: 768px) {
      height: 350px;
      width: 350px;
    }
    
    @media (min-width: 1024px) {
      height: 250px;
      width: 250px;
    }

    @media (min-width: 1024px)
    and (min-height: 1366px) {
      height: 500px;
      width: 500px;
    }
    
    @media (min-width: 1280px) {
      height: 400px;
      width: 400px;
    }
    
    @media (min-width: 1440px) {
      height: 500px;
      width: 500px;
      z-index: 22;
    }

    &[data-area='about'] {
      top: 80%;

      @media (min-width: 375px) {
        top: 84%;
      }

      @media (min-width: 480px) {
        top: 75%;
      }
      
      @media (min-width: 540px) {
        top: 85%;
      }
         
      @media (min-width: 768px) {
        top: 85%;
      }
      
      @media (min-width: 1280px) {
        top: 80%;
      }

      @media (min-width: 1440px) {
        height: 500px;
        top: 120%;
        width: 500px;
      }
      
      @media (min-width: 1920px) {
        height: 600px;
        top: 125%;
        width: 600px;
      }
    }
    
    &[data-mask='about'] {
      background: url('../../images/stell-about-1-hands-circle.png') top center no-repeat;
      background-size: cover;
    }
    
    &[data-mask='coffee'] {
      background: url('../../images/stell-about-2-coffee-circle.png') top center no-repeat;
      background-size: cover;
      opacity: 0;

      @media (min-width: 1440px) {
        top: 0;
      }
      
      @media (min-width: 1920px) {
        height: 600px;
        width: 600px;
      }
    }
    
    &[data-mask='beer'] {
      background: url('../../images/stell-about-4-craft-beer-circle.png') top center no-repeat;
      background-size: cover;
      opacity: 0;

      @media (min-width: 1440px) {
        top: 0;
      }
      
      @media (min-width: 1920px) {
        height: 600px;
        width: 600px;
      }
    }
    
    &[data-area='coffee'] {
      background: url('../../images/stell-about-2-coffee-circle.png') top center no-repeat;
      background-size: cover;
      top: 180%;
      
      @media (min-width: 375px) {
        top: 184%;
      }
      
      @media (min-width: 768px) {
        top: 180%;
      }

      @media (min-width: 1440px) {
        display: none;
      }
    }
    
    &[data-area='beer'] {
      background: url('../../images/stell-about-4-craft-beer-circle.png') top center no-repeat;
      background-size: cover;
      top: 380%;

      @media (min-width: 375px) {
        top: 384%;
      }

      @media (min-width: 375px) 
      and (min-height: 812px) {
        top: 385%;
      }

      @media (min-width: 768px) {
        top: 380%;
      }

      @media (min-width: 1024px) {
        top: 385%;
        z-index: 9999;
      }
      
      @media (min-width: 1024px)
      and (min-height: 1366px) {
        top: 380%;
        z-index: 9999;
      }
      
      @media (min-width: 1440px) {
        display: none;
      }
    }
  }
}