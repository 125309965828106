.stellco {
  &_footer {
    // border: thin solid white;
    background: var(--stell-orange);
    height: 100vh;
    position: relative;
    width: 100vw;

    &__content {
      position: relative;
      width: 100%;

      @media (min-width: 1024px) {
        height: 100%;
      }
    }

    &__content___wrapper {
      // border: thin solid white;
      display: flex;
      flex-direction: column;
      padding-top: 60px;
      position: relative;
      width: 100%;

      @media (min-width: 375px) {
        padding-top: 75px;
      }

      @media (min-width: 375px) {
        padding-top: 100px;
      }
      
      @media (min-width: 400px) {
        padding-top: 100px;
      }
      
      @media (min-width: 768px) {
        padding-top: 100px;
      }
     
      @media (min-width: 768px)
      and (min-height: 1024px) {
        padding-top: 150px;
      }
      
      @media (min-width: 812px)
      and (min-height: 768px) {
        padding-top: 50px;
      }

      @media (min-width: 1024px) {
        flex-direction: row;
        left: 50%;
        padding-top: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 85%;
      }
      
      @media (min-width: 1440px) {
        flex-direction: row;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 75%;
      }
    }
    
    &__header {
      // border: thin solid white;
      margin: auto;
      width: 80%;

      @media (min-width: 1024px) {
        padding: 0;
        position: relative;
        width: 50%;
      }

      &_text {
        @include JanaThorkProBold;
        color: var(--stell-white);
        font-size: 60px;
        line-height: .9;
        text-transform: uppercase;

        @media (min-width: 768px) {
          font-size: 105px;
        }
        
        @media (min-width: 768px)
        and (min-height: 1024px) {
          font-size: 130px;
        }
        
        @media (min-width: 768px)
        and (min-height: 812px) {
          font-size: 125px;
        }
        
        @media (min-width: 812px)
        and (min-height: 768px) {
          font-size: 125px;
        }
        
        @media (min-width: 1024px) {
          font-size: 118px;
        }
        
        @media (min-width: 1440px) {
          font-size: 120px;
        }
        
        @media (min-width: 1900px) {
          font-size: 200px;
        }
      }
    }
    
    &__information {
      // border: thin solid white;
      color: var(--stell-white);
      display: block;
      padding: 20px 0;
      margin: auto;
      width: 80%;

      @media (min-width: 400px) {
        padding: 30px 0;
      }
      
      @media (min-width: 540px) {
        padding: 50px 0;
      }
      
      @media (min-width: 375px)
      and (min-height: 812px) {
        padding: 75px 0;
      }

      @media (min-width: 720px) {
        padding: 25px 0 0;
      }
      
      @media (min-width: 768px) {
        padding: 40px 0;
      }
      
      @media (min-width: 812px)
      and (min-height: 768px) {
        padding: 25px 0;
      }

      @media (min-width: 1024px) {
        padding: 0 0 0 125px;
        margin: 0;
        width: 50%;
      }

      &___visit {
        // border: thin solid white;
        position: relative;

        &_label {
          @include JanaThorkProBold;
          font-size: 18px;
          line-height: 1.5;
          margin-bottom: 10px;
          text-transform: uppercase;

          @media (min-width: 720px) {
            font-size: 18px;
            line-height: 1;
            margin-bottom: 0;
          }
          
          @media (min-width: 768px) {
            font-size: 18px;
          }
        }
        
        &_text {
          font-size: 12px;
          line-height: 1.5;

          @media (min-width: 768px) {
            font-size: 18px;
          }
        }
      }

      &___location_wrapper {
        // border: thin solid white;
        display: flex;
        padding: 10px 0;
        position: relative;
        font-size: 12px;

        &[data-locations] {
          .stellco_footer__information___location_text {
            text-decoration: underline;

            @media (min-width: 768px) {
              font-size: 14px;
            }
            
            @media (min-width: 1440px) {
              font-size: 18px;
            }
          }
        }
        
        &[data-social] {
          .stellco_footer__information___location_text.email {
            padding: 10px 0;
          }
        }

        @media (min-width: 540px) {
          padding: 20px 0;
        }
        
        @media (min-width: 720px) {
          padding: 10px 0;
        }
        
        @media (min-width: 768px) {
          font-size: 14px;
        }
        
        @media (min-width: 768px)
        and (min-height: 1024px) {
          font-size: 18px;
        }
        
        @media (min-width: 812px) 
        and (min-height: 768px) {
          font-size: 12px;
          padding: 10px 0;
        }
        
        
        @media (min-width: 1024px) {
          font-size: 14px;
          padding: 30px 0;
        }
      }
      
      &___location {
        // border: thin solid white;
        font-size: 11px;
        line-height: 1.5;
        width: 50%;

        &_text {
          color: var(--stell-white); 
          display: block;

          @media (min-width: 768px) {
            font-size: 14px;
          }
          
          @media (min-width: 1440px) {
            font-size: 18px;
          }
        }

        &_heading {
          @include JanaThorkProBold;
          font-size: 18px;
          text-transform: uppercase;

          @media (min-width: 1024px) {
            font-size: 24px;
          }
        }
        
        &_social {
          display: flex;
          margin: 10px 0;
          position: relative;
        }
        
        &_socialIcon {
          // border: thin solid white;
          height: 20px;
          margin: 3px 10px 0 0;
          width: 20px;

          @media (min-width: 1024px) {
            height: 25px;
            margin: 0 10px 0 0;
            width: 25px;
          }

          &.svg {
            fill: var(--stell-white);
            height: 100%;
            width: 100%;
          }
        }
      }
    }

    &__copyright {
      // border: thin solid blue;
      position: relative;
      height: 10%;
      width: 100%;

      @media (min-width: 375px) {
        height: 15%;
        margin-top: 25px;
      }

      @media (min-width: 375px)
      and (min-height: 812px) {
        margin-top: 50px;
      }
      
      @media (min-width: 400px) {
        height: 15%;
        margin-top: 50px;
      }
      
      @media (min-width: 768px) {
        margin-top: 30px;
      }
      
      @media (min-width: 768px)
      and (min-height: 1024px) {
        margin-top: 50px;
      }
      
      @media (min-width: 812px) 
      and (min-height: 768px) {
        height: 10%;
      }
      
      @media (min-width: 1024px) {
        height: 5%;
        margin-top: -75px;
      }

      &___text {
        color: var(--stell-white);
        font-size: 12px;
        margin: auto;
        padding: 10px 0;
        text-align: center;
        width: 50%;

        @media (min-width: 400px) {
          padding: 20px 0;
        }
        
        @media (min-width: 720px) {
          padding: 10px 0;
        }

        @media (min-width: 768px) {
          font-size: 18px;
        }

        @media (min-width: 768px)
        and (min-height: 812px) {
          padding: 0;
        }
        
        @media (min-width: 812px)
        and (min-height: 768px) {
          font-size: 14px;
          padding: 0;
        }

        @media (min-width: 1024px) {
          color: var(--stell-white);
          font-size: 12px;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      
      &___studiosupermassive {
        color: var(--stell-white);
        display: flex;
        position: relative;

        @media (min-width: 1024px) {
          color: var(--stell-white);
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 50px;
          width: 180px;
        }
        
        @media (min-width: 1440px) {
          color: var(--stell-white);
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 50px;
          width: 180px;
        }

        &_designBy {
          font-size: 12px;
          margin: auto;
          padding: 20px 0;
          text-align: left;
          width: 125px;

          @media (min-width: 320px) {
            width: 100px;
          }
          
          @media (min-width: 768px) {
            font-size: 18px;
            width: 160px;
          }
          
          @media (min-width: 1024px) {
            width: 100%;
          }
        }

        &_logo {
          background: url('../../images/icons/ssm-logo-white.svg') top center no-repeat;
          background-size: contain;
          left: 62%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          text-indent: -100000px; 
          height: 25px;
          width: 45px;

          @media (min-width: 720px) {
            left: 57%;
          }

          @media (min-width: 1024px) {
            left: auto;
            right: 0;
          }
        }
      }
    }
  }
}