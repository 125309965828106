.stellco {
  &_beer {
    height: 100vh;
    position: relative;
    width: 100vw;

    @media (min-width: 768px) {
      height: 100vh;
    }

    &__content {
      // border: thin solid red;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      width: 90%;
      z-index: 22;

      @media (min-width: 320px) {
        width: 80%;
      }

      @media (min-width: 768px) {
        top: 55%;
        width: 65%;
      }
      
      @media (min-width: 768px)
      and (min-height: 1024px) {
        top: 50%;
        width: 80%;
      }

      @media (min-width: 1024px) {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 85%;
      }
      
      @media (min-width: 1024px)
      and (min-height: 1366px) {
        width: 75%;
      }
      
      @media (min-width: 1440px) {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }
      
      @media (min-width: 1900px) {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 82%;
      }
    }

    &__heading {
      // border: thin solid red;
      @include JanaThorkProBold;
      color: var(--stell-cyan);
      font-size: 60px;
      line-height: .8;
      padding-bottom: 15px;
      text-transform: uppercase;
      // width: 80%;
      z-index: 22;

      @media (min-width: 320px) {
        font-size: 72px;
      }
      
      @media (min-width: 375px) {
        font-size: 80px;
      }
      
      @media (min-width: 768px) {
        font-size: 105px;
        line-height: .8;
      }
      
      @media (min-width: 768px)
      and (min-height: 1024px) {
        font-size: 150px;
        line-height: .8;
      }
      
      @media (min-width: 768px)
      and (min-height: 812px) {
        font-size: 125px;
      }
      
      @media (min-width: 812px)
      and (min-height: 768px) {
        font-size: 125px;
      }
      
      @media (min-width: 1024px) {
        font-size: 120px;
        width: 50%;
      }

      @media (min-width: 1024px)
      and (min-height: 1366px) {
        font-size: 180px;
        width: 100%;
      }

      @media (min-width: 1280px) {
        font-size: 150px;
      }
      
      @media (min-width: 1440px) {
        font-size: 150px;
        left: 10%;
        line-height: .8;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 640px;
      }
      
      @media (min-width: 1900px) {
        font-size: 200px;
        left: 0;
        line-height: .8;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 640px;
      }
    }
    
    &__description {
      // border: thin solid white;
      color: var(--stell-black);
      font-size: 14px;
      line-height: 1.5;
      // width: 80%;

      @media (min-width: 375px) {
        font-size: 18px;
      }

      @media (min-width: 768px) {
        font-size: 18px;
      }
      
      @media (min-width: 768px)
      and (min-height: 1024px) {
        font-size: 24px;
      }

      @media (min-width: 1024px) {
        font-size: 18px;
        left: auto;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -75%);
        width: 40%;
      }

      @media (min-width: 1024px)
      and (min-height: 1366px) {
        font-size: 24px;
        line-height: 1.5;
        position: relative;
        right: auto;
        top: auto;
        transform: translate(0);
        width: 100%;
      }
      
      @media (min-width: 1440px) {
        font-size: 18px;
        left: auto;
        position: absolute;
        right: 7%;
        top: 50%;
        transform: translate(0, -75%);
        width: 400px;
      }
      
      @media (min-width: 1900px) {
        font-size: 21px;
        left: auto;
        position: absolute;
        right: 10%;
        top: 50%;
        transform: translate(0, -75%);
        width: 22%;
      }
    }
  }
}