.stellco {
  &_bio {
    background: var(--stell-orange);
    background-size: 50%;
    height: 100vh;
    position: relative;
    width: 100vw;

    &__content {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      z-index: 22;

      @media (min-width: 768px) {
        top: 50%;
        width: 65%;
      }
      
      @media (min-width: 768px) 
        and (min-height: 1024px) {
        top: 50%;
        width: 80%;
      }

      @media (min-width: 1024px) 
      and (min-height: 1366px) {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
        z-index: 22;
      }

      @media (min-width: 1440px) {
        display: flex;
        width: 90%;
      }
    }

    &__services {
      // border: thin solid white;
      color: var(--stell-white);
      font-size: 28px;
      padding-bottom: 15px;
      text-transform: uppercase;
      z-index: 22;

      @media (min-width: 375px) {
        font-size: 34px;
      }
      
      @media (min-width: 400px) {
        font-size: 39px;
      }

      @media (min-width: 768px) {
        font-size: 36px;
      }
      
      @media (min-width: 768px) 
      and (min-height: 1024px) {
        font-size: 52px;
      }
      
      @media (min-width: 1024px) {
        font-size: 52px;
        // border: thin solid white;
        width: 55%;
      }
      
      @media (min-width: 1024px) 
      and (min-height: 1366px) {
        font-size: 72px;
        width: 100%;
      }

      @media (min-width: 1440px) {
        font-size: 50px;
        left: 5%;
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
        width: 34%;
      }
      
      @media (min-width: 1920px) {
        font-size: 68px;
        width: 30%;
      }
    }
    
    &__about {
      // border: thin solid white;
      color: var(--stell-white);
      font-size: 14px;
      line-height: 1.5;
      z-index: 21;

      @media (min-width: 375px) {
        font-size: 16px;
      }

      @media (min-width: 400px) {
        font-size: 18px;
      }

      @media (min-width: 768px) {
        font-size: 16px;
      }

      @media (min-width: 768px) 
      and (min-height: 1024px) {
        font-size: 18px;
      }

      @media (min-width: 1024px) {
        // border: thin solid white;
        font-size: 18px;
        right: 0;
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
        width: 40%;
      }
      
      @media (min-width: 1024px)
      and (min-height: 1366px) {
        font-size: 24px;
        line-height: 1.5;
        right: auto;
        top: auto;
        position: relative;
        transform: translateY(0);
        width: 100%;
      }
      
      @media (min-width: 1440px) {
        font-size: 18px;
        // left: auto;
        right: 0;
        top: 60%;
        position: absolute;
        transform: translateY(-50%);
        width: 30%;
      }
      
      @media (min-width: 1900px) {
        font-size: 21px;
        right: 10%;
        top: 50%;
        width: 22%;
      }
    }
  }
}