.stellco {
  &_action {
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 65%;
    transform: translate(-50%, -50%);
    transition: all .35s ease-out;
    z-index: 50;

    @media (min-width: 320px) {
      top: 64%;
    }
    
    @media (min-width: 360px) {
      top: 63%;
    }

    @media (min-width: 480px) {
      top: 70%;
    }
   
    @media (min-width: 700px) {
      top: 78%;
    }

    @media (min-width: 768px) 
    and (min-height: 1024px) {
      top: 62%;
    }
   
    @media (min-width: 1024px) {
      position: fixed;
      left: unset;
      right: -60px;
      top: 50%;
      transform: translate(0) rotate(90deg);
      z-index: 50;
    }

    @media (min-width: 1440px) {
      right: 25px;
      position: fixed;
      transform: translateY(0) rotate(0);
      transition: all .25s ease-out;
      top: 40px;
      z-index: 150;

      &:hover {
        transform: scale(1.1);
      }
    }
    
    @media (min-width: 1600px) {
      right: 30px;
      position: fixed;
      transform: translateY(0) rotate(0);
      transition: all .25s ease-out;
      top: 40px;
      z-index: 150;

      &:hover {
        transform: scale(1.1);
      }
    }

    &__btn {
      background: var(--stell-orange);
      border-radius: 0;
      border-radius: 25px;
      color: var(--stell-white);
      font-size: 8px;
      font-weight: bold;
      letter-spacing: 1px;
      padding: 15px 20px;
      text-transform: uppercase;
      text-decoration: none;
      transition: all .25s ease-out;

      @media (min-width: 320px) {
        font-size: 10px;
      }

      @media (min-width: 700px) {
        border-radius: 50px;
        font-size: 12px;
        padding: 20px 30px;
      }

      @media (min-width: 768px) 
      and (min-height: 1024px) {
        border-radius: 50px;
        font-size: 14px;
        padding: 25px 40px;
      }
      
      @media (min-width: 1024px) {
        border-radius: 50px;
        font-size: 12px;
        padding: 15px 20px;
      }
      
      @media (min-width: 1024px)
      and (min-height: 1366px) {
        font-size: 16px;
        padding: 20px 40px;
      }

      &[data-type='orange'] {
        background: var(--stell-orange);
        color: var(--stell-white);
      }
      
      &[data-type='white'] {
        background: var(--stell-white);
        color: var(--stell-orange);
      }
      
      &[data-type='black'] {
        background: var(--stell-white);
        color: var(--stell-black);
      }
    }
  }
}