.stellco {
  &_menu {
    display: flex;
    flex-flow: column;
    height: 100vh;
    width: 100%;

    @media (min-width: 1024px) {
      flex-flow: row;
    }

    &__preview {
      height: 40%;
      position: relative;
      width: 100%;

      &[data-menu="coffee"] {
        background: url('../../images/stell-menu-coffee.jpg') center center no-repeat;
        background-size: cover;
      }
      
      &[data-menu="bakery"] {
        background: url('../../images/stell-menu-bakery.jpg') center center no-repeat;
        background-size: cover;
      }
      
      &[data-menu="breakfast"] {
        background: url('../../images/stell-menu-breakfast.jpg') center center no-repeat;
        background-size: cover;
      }
      
      &[data-menu="lunch"] {
        background: url('../../images/stell-menu-lunch-dinner.jpg') center center no-repeat;
        background-size: cover;
      }
      
      &[data-menu="blended"] {
        background: url('../../images/stell-menu-blended.jpg') center center no-repeat;
        background-size: cover;
      }
      
      &[data-menu="tea"] {
        background: url('../../images/stell-menu-tea.jpg') center center no-repeat;
        background-size: cover;
      }
      
      &[data-menu="milk"] {
        background: url('../../images/stell-menu-milk.jpg') center center no-repeat;
        background-size: cover;
      }
      
      &[data-menu="kombucha"] {
        background: url('../../images/stell-menu-kombucha.jpg') center center no-repeat;
        background-size: cover;
      }
      
      &[data-menu="beans"] {
        background: url('../../images/stell-menu-beans.jpg') center center no-repeat;
        background-size: cover;
      }

      @media (min-width: 1024px) {
        height: 100%;
        width: 40%;
      }
    }

    &__paddles {
      // border: thin solid red;
      bottom: 0;
      height: 50px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      @media (min-width: 1024px) {
        display: none;
        left: auto;
        right: 0;
        transform: translate(0, 0);
        top: auto;
        bottom: 0;
        width: 15%;
      }

      &___left, &___right {
        // border: thin solid red;
        background: var(--stell-white-50);
        border: 0;
        height: 150%;
        width: 65px;

        &[data-limit='true'] {
          .stellco_menu__paddles___svg {
            fill: var(--stell-white-50);
          }
        }

        &[data-limit='false'] {
          .stellco_menu__paddles___svg {
            fill: #222;
          }

          &:hover {
            .stellco_menu__paddles___svg {
              fill: var(--stell-orange);
            }
          }
        }
      }
      
      &___left {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        left: 0;
        position: absolute;
      }
      
      &___right {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        right: 0;
        position: absolute;
      }
      
      &___svg {
        height: 75%;
        width: 75%;
      }
    }
    
    &__choices {
      height: 50%;
      width: 100%;

      @media (min-width: 1024px) {
        height: 100%;
        width: 60%;
      }

      &_selections {
        display: flex;
        padding-top: 0;
        // height: 60%;

        @media (min-width: 1024px) {
          padding-top: 20px;
        }

        @media (min-width: 1024px)
          and (min-height: 1366px) {
            padding-top: 150px;
          }
        
        @media (min-width: 1440px) {
          // border: thin solid red;
          padding-top: 50px;
          width: 100%;
        }
        
        @media (min-width: 1920px) {
          padding-top: 200px;
        }
      }
      
      &_selection {
        // border: thin solid blue;
        height: 100%;
        padding: 0;

        @media (min-width: 1024px) {
          height: 100%;
          padding: 70px 50px 0;
        }
        
        @media (min-width: 1280px) {
          height: 100%;
          padding: 150px 0 0 50px;
          width: 100%;
        }
        
        @media (min-width: 1440px) {
          height: 100%;
          padding: 100px 0 0 50px;
          width: 100%;
        }
        
        @media (min-width: 1600px) {
          height: 100%;
          padding: 100px 0 0 100px;
          width: 100%;
        }
        
        @media (min-width: 1920px) {
          height: 100%;
          padding: 50px 0 0 200px;
        }
        
        @media (min-width: 2560px) {
          height: 100%;
          padding: 0 0 0 150px;
        }

        &_name {
          margin: auto;
          width: 80%;

          @media (min-width: 768px) {
            width: 65%;
          }
          
          @media (min-width: 768px)
          and (min-height: 1024px) {
            width: 80%;
          }
          
          @media (min-width: 1024px) {
            margin: 0;
            width: 100%;
          }
          
          @media (min-width: 1440px) {
            margin: 0;
            width: 100%;
          }
          
          @media (min-width: 1920px) {
            margin: 0;
            width: 70%;
          }
        }
        
        &_header {
          @include JanaThorkProBold;
          font-size: 38px;
          padding: 30px 0 10px;
          text-transform: uppercase;

          @media (min-width: 375px) {
            font-size: 38px;
            padding: 50px 0 10px;
          }
          
          @media (min-width: 375px)
          and (min-height: 812px) {
            padding: 50px 0 10px;
          }
          
          @media (min-width: 768px) {
            font-size: 64px;
            padding: 75px 0 10px;
          }
          
          @media (min-width: 768px)
          and (min-height: 1024px) {
            font-size: 75px;
            padding: 75px 0 10px;
          }

          @media (min-width: 1024px) {
            font-size: 48px;
            padding: 10px 0;
          }
          
          @media (min-width: 1024px)
          and (min-height: 1366px) {
            font-size: 42px;
            padding: 10px 0;
          }
          
          @media (min-width: 1280px) {
            font-size: 72px;
          }
          
          @media (min-width: 1600px) {
            font-size: 84px;
          }
          
          @media (min-width: 1920px) {
            font-size: 84px;
            padding: 10px 0;
          }
        }
        
        &_description {
          font-size: 14px;
          line-height: 1.75;

          @media (min-width: 375px) {
            font-size: 18px;
          }
          
          @media (min-width: 768px) {
            font-size: 18px;
          }
          
          @media (min-width: 768px)
          and (min-height: 1024px) {
            font-size: 24px;
          }
          
          @media (min-width: 1024px) {
            font-size: 14px;
          }
          
          @media (min-width: 1440px) {
            font-size: 16px;
          }
          
          @media (min-width: 1920px) {
            font-size: 18px;
          }
        }

        &_price {
          @include JanaThorkProBold;
          font-size: 28px;
          color: var(--stell-cyan);
          margin: auto;
          padding: 20px 0;
          width: 80%;

          @media (min-width: 1024px) {
            font-size: 42px;
            margin: 0;
            padding: 20px 0;
          }
        }
      }
      
      &_items {
        // border: thin solid blue;
        display: none;
        text-align: right;
        padding: 0 75px;
        width: 50%;

        @media (min-width: 1024px) {
          padding: 80px 30px 0;
          display: block;
        }
        
        @media (min-width: 1280px) {
          padding: 120px 30px 0;
          display: block;
        }
        
        @media (min-width: 1440px) {
          padding: 80px 60px 0;
          display: block;
        }
        
        @media (min-width: 1920px) {
          padding: 0 60px 0;
          display: block;
        }

        &_list {
          list-style: none;
          margin: 0;
          padding: 0;
          position: relative;
          text-transform: uppercase;
        }
        
        &_name {
          color: var(--stellco-black);
          display: block;
          font-size: 12px;
          font-weight: bold;
          padding: 10px 0;
          text-decoration: none;

          &[data-active='true'] {
            color: var(--stell-orange);
            font-weight: bold;
            position: relative;
          }
        }
      }
      
      &_footer {
        @include JanaThorkProBold;
        display: none;
        color: var(--stell-cyan);
        font-size: 150px;
        line-height: .9;
        padding: 40px 100px 0 200px;
        text-align: right;
        text-transform: uppercase;
        height: 40%;

        @media (min-width: 1024px) {
          display: block;
          padding: 75px 100px 0 100px;
          font-size: 120px;
        }
        
        @media (min-width: 1280px) {
          display: block;
          padding: 150px 100px 0 100px;
          font-size: 150px;
        }
        
        @media (min-width: 1440px) {
          display: block;
          padding: 50px 100px 0 100px;
          font-size: 120px;
        }
        
        @media (min-width: 1600px) {
          display: block;
          padding: 150px 100px 0 100px;
          font-size: 160px;
        }
        
        @media (min-width: 1920px) {
          font-size: 200px;
          padding: 350px 100px 0 200px;
        }
      }
    }
  }
}