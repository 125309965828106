.sprite {
  background: url('../../images/coffee-sprite-optim.jpg') 0 0 no-repeat;
  border: thin solid transparent;
  border-radius: 500px;
  display: none;
  height: 500px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  z-index: 20;
  
  @media (min-width: 1440px) {
    display: block;
  }
}

.sprite2 {
  background: url('../../images/beer-sprite-optim.jpg') 0 0 no-repeat;
  border: thin solid transparent;
  border-radius: 500px;
  display: none;
  height: 500px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  z-index: 10;
  
  @media (min-width: 1440px) {
    display: block;
  }
}

#Layer_1 {
  height: 450px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 540px;
}

.st0{display:none;}
.st1{display:inline;}
.st2{display:inline;fill:none;stroke:#000000;stroke-miterlimit:10;}
.st3{display:inline;fill:none;stroke:#FFFFFF;stroke-width:10;stroke-miterlimit:10;}
.st4{fill:#FFFFFF;}

.stellco_preloader {
  // border: thin solid red;
  background: url('../../images/stell-logo-large-0-gray.png') top center no-repeat;
  background-size: contain;
  height: 400px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(.30);
  width: 550px;

  @media (min-width: 768px) {
    transform: translate(-50%, -50%) scale(.70);
  }

  @media (min-width: 1024px) {
    transform: translate(-50%, -50%) scale(.50);
  }
  
  @media (min-width: 1440px) {
    transform: translate(-50%, -50%);
  }

  .cls-1 {
    fill: var(--stell-orange);
    opacity: 1;
  }

  @keyframes preloader {
    0% {
      y:400px;
    };
    100% {
      y:0;
    }
  }

  rect {
    animation: preloader 7s;
    animation-iteration-count: 1;
    fill: var(--stell-orange);
    height: 400px;
    x:0px;
    width: 550px;
  }

  &__progress {
    background: transparent;
    border: thin solid var(--stell-orange);
    bottom: 0;
    // clip-path: polygon(3% 72%, 3% 50%, 9% 48%, 1% 23%, 3% 8%, 9% 2%, 42% 1%, 43% 11%, 58% 11%, 58% 1%, 74% 1%, 83% 1%, 94% 1%, 94% 19%, 94% 32%, 94% 49%, 99% 51%, 99% 71%, 94% 69%, 89% 82%, 84% 85%, 81% 93%, 73% 100%, 57% 100%, 42% 100%, 28% 100%, 21% 91%, 12% 82%, 9% 70%);
    height: 400px;
    position: absolute;
    left: 50%;
    transition: all .25s ease-out;
    transform: translateX(-50%);
    width: 550px;
  }

  &__fill {
    background: var(--stell-orange);
    position: absolute;
    left: 50%;
    bottom: 0;
    transition: all .25s ease-out;
    transform: translateX(-50%);
    width: 550px;
    height: 0;
    z-index: 200;
  }
  
  &__logo2 {
    bottom: 0;
    height: 400px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 550px;
    z-index: 0;

    @media (min-width: 1024px) {
      height: 400px;
      width: 550px;
    }
  }
  
  &__logo {
    bottom: 0;
    height: 400px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 550px;
    z-index: 0;

    @media (min-width: 1024px) {
      height: 400px;
      width: 550px;
    }
  }
}